<template>
	<!-- 拼写单词 -->
	<div class="home_conbox" v-if="wordobj.word">
		<div class="page-content">
			<div class="progress-out">
				<div class="progress-in" :style="{width: progresstext+'%'}"></div>
				<span class="now-num">{{wordindex+1}}</span>
				<span class="whold-num">{{wordList.length}}</span>
			</div>
			<div class="word-sounds">
				<div class="linsten-block">
					<div class="linsten-btn" @click="playAudio(wordobj.phAmMp3)">
						<img src="@/assets/static/linsten.png" mode="" />
					</div>
					<span class="commonligatures" v-if="wordobj.phAm">美 /{{wordobj.phAm}}/</span>
				</div>
				<div class="word-pic" v-if="wordobj.img1"><myImg :src="wordobj.img1"></myImg></div>
				<div class="desc-item" v-if="wordobj.wordTpye" v-for="(sitem,index) in wordobj.wordTpye" :key="index">
					<span class="textpart">{{ sitem.part }}</span>
					<div class="desc2cn">
						<span class="desc2" v-for="(mitem, mindex) in sitem.means"
							:key="mindex" v-if="mitem">{{ mitem }}<span class="fenhao">;</span><span decode="true">&nbsp;</span>
						</span>
					</div>
				</div>
			</div>
			<input class="word-input" placeholder="请输入" @confirm="handletijiao" :cursor-spacing="38" type="text" @focus="showAnswer = false" v-model="keywords"/>
			<div class="right-tip" v-if="showAnswer">正确拼写：{{wordobj.word}}</div>
			<div class="block58"></div>
		</div>
		<div class="wordbtnbox">
			<div class="btn btn1" @click="checkanswer()">看答案</div>
			<div class="btn btn2" @click="handletijiao()">提交</div>
		</div>
	</div>
	<!-- 拼写 end-->
</template>

<script>
	export default {
		props: {
			list: {
				type: Array,
				default: () => {
					return [];
				}
			},
		},
		watch: {
			list: {
				immediate:true,
			    handler(newVal, objVal) {
					if(newVal&&newVal.length>0){
						this.wordList = newVal
						this.initfunc()
					}
			    },
			},
		},
		data() {
			return {
				isLinstening: false, //是否正在播放音频
				wordList:[],//单词列表
				wordobj:{},//当前学习的下标
				wordindex:0,//当前学习的下标
				collectList:[],//收藏本集合
				isShowcollec:false,//展示收藏
				showAnswer:false,//展示答案
				keywords:"",//输入值
				progresstext:0,//进度
			};
		},
		methods: {
			//初始化
			initfunc(){
				this.wordList.forEach((item, index) => {
					item["cuowunum"] = 0
					try{
						item["wordTpye"] =  JSON.parse(item.definition)
					}catch(e){
						item["wordTpye"] = []
					}
				});
				this.wordindex = 0
				this.wordobj = this.wordList[0]
			},
			//看答案
			checkanswer(){
				this.showAnswer = true
			},
			//提交
			handletijiao(){
				var _this = this
				if(this.keywords){
					if(this.keywords==this.wordobj.word){
						this.$message({
							offset:155,
						    message: '拼写正确',
						    type: 'success'
						});
						if(this.wordindex >= this.wordList.length-1){
							//最后一道题
							this.$emit("handlefinish")
						}else{
							//正确，跳转下一个单词
							var timetr = setTimeout(function(){
								clearTimeout(timetr)
								_this.showAnswer = false
								_this.keywords = ""
								_this.wordindex +=1
								_this.wordobj = _this.wordList[_this.wordindex]
								_this.getjindu()
							},1000)
						}
					}else{
						this.$message({
							offset:155,
						    message: '拼写错误',
						    type: 'error'
						});
						this.showAnswer = true
					}
				}else{
					this.$message({
						offset:155,
					    message: '请输入单词',
					    type: 'info'
					});
				}
			},
			//进度
			getjindu(){
				const num = this.wordindex + 1
				if(num){
					this.progresstext = Number(((num / this.wordList.length)  * 100).toFixed(0));
				}else{
					this.progresstext = 0
				}
			},
			// 拼接
			join(str,key){
				var reg = new RegExp((`(${key})`), "gm");
				//var replace = `<span class="sentence-wrod-style">I need some children's <span class="sentence-wrod-style-block"><div class="sentence-key-wrod-style"></div><span class="sentence-key-wrod-text">crayons</span></span>.</span>`
				var replace = '<span class="sentence-wrod-style-block"><span class="sentence-key-wrod-style"></span><span class="sentence-key-wrod-text" style="font-weight:bold;">$1</span></span>';
				return str.replace(reg, replace);
			},
			
		},
	};
</script>

<style lang="scss" scoped>
	.home_conbox {
		width: 100%;
	}
	.page-content {
		width: 100%;
		min-height: 538px;
		max-height: 588px;
		overflow-y: auto;
		position: relative;
		z-index: 2;
		border-radius: 10px;
		background: #FFFFFF;
		padding: 18px 16px;
		box-sizing: border-box;
		.progress-out {
			width: 100%;
			height: 12px;
			border-radius: 12px;
			position: relative;
			display: flex;
			align-items: center;
			background: #F0EDED;
			font-size: 14px;
			margin-bottom: 10px;
			.progress-in {
				border-radius: 12px;
				background: #51D9A5;
				height: 12px;
			}
					
			.now-num {
				height: 12px;
				position: absolute;
				top: 0;
				left: 18px;
				line-height: 12px;
				color: #5C3704;
			}
					
			.whold-num {
				height: 12px;
				position: absolute;
				top: 0;
				right: 18px;
				color: #5C3704;
				line-height: 12px;
			}
		}
	
		.word-sounds {
			padding: 18px 0;
			border-bottom: 1px dashed #DBDBDB;
			.desc-item {
				display: flex;
				margin-top: 16px;
				line-height: 18px;
				font-size: 14px;
				.desc2cn {
					display: flex;
					flex-wrap: wrap;
				}
				.desc2 {
					margin-left: 5px;
					font-size: 14px;
				}
				.textpart {
					color: #999999;
					margin-right: 5px;
				}
			}
			.linsten-block {
				margin-top: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				.commonligatures {
					font-size: 14px;
					margin-left: 8px;
					color: #666;
				}
			}
				
			.linsten-btn {
				border-radius: 20px;
				border: 1px solid #e0e0e0;
				background: #fff;
				box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
				height: 24px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				
				image {
					width: 16px;
					height: 16px;
				}
			}
				
			.word-pic {
				margin: 14px auto 0;
				width: 100px;
				height: 60px;
				border-radius: 6px;
				background-color: #fdf0cf;
				overflow: hidden;
			}
		}
	
		.word-input {
			margin-top: 18px;
			background: #F0EDED;
			border-radius: 6px;
			font-size: 15px;
			color: #222;
			padding: 0 16px;
			height: 48px;
			width: 100%;
			box-sizing: border-box;
		}
	
		.right-tip {
			margin-top: 18px;
			text-align: center;
			font-size: 14px;
			color: #222222;
		}
	
	}
	.wordbtnbox{
		width: 88%;
		margin: 0 auto;
		padding: 18px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.btn{
			width: 46%;
			height: 48px;
			border-radius: 6px;
			color: #fff;
			font-size: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.btn1{
			border: 1px solid #499DFF;
			background: #67ADFF;
		}
		.btn2{
			border: 1px solid #68CDA6;
			background: #51D9A5;
		}
	}
	
</style>