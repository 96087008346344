<template>
	<!-- 拼写单词 -->
	<div class="home_conbox" v-if="wordobj.word">
		<!-- 音频 -->
		<audio controls ref="commonaudioCom" class="disnone">
			<source src="" />
		</audio>
		<!-- 音频 -->
		<div class="content-panel">
			<div class="progress-out">
				<div class="progress-in" :style="{width: progresstext+'%'}"></div>
				<span class="now-num">{{wordindex+1}}</span>
				<span class="whold-num">{{wordList.length}}</span>
			</div>
			<div class="block20" ></div>
			<div class="word-text"  v-if="showtype==2">{{wordobj.word}}</div>
			<!-- 词读音 -->
			<div class="linsten-block">
				<div class="linsten-btn" @click="playAudioCom(wordobj.phAmMp3)">
					<img src="@/assets/static/linsten.png" mode="" />
				</div>
				<span v-if="wordobj.phAm">美 /{{wordobj.phAm}}/</span>
			</div>
			<div class="mid-line"></div>
			<div class="s-tip">请从下列4个选择中选择正确的词义</div>
			<div class="select-list">
				<div v-for="(item,index) in optionsList" :key="index">
					<div v-if="item.optionName" class="select-item" :class="{'error':optionsindex==index&&optionsflag==2,'right':optionsindex>=0&&wordobj.word==item.word}" @click="handleoption(index)">
						<img v-if="optionsindex>=0&&optionsindex==index&&optionsflag==2" class="select-icon" src="@/assets/static/come_on.png"></img>
						<img v-if="optionsindex>=0&&wordobj.word==item.word" class="select-icon" src="@/assets/static/great.png" ></img>
						<span class="clamp">{{optionstext[index]}}. <span v-html="item.optionName"></span></span>
					</div>
					<div v-else class="select-item" :class="{'error':optionsindex==index&&optionsflag==2,'right':optionsindex>=0&&wordobj.word==item.word}" @click="handleoption(index)">
						<img v-if="optionsindex>=0&&optionsindex==index&&optionsflag==2" class="select-icon" src="@/assets/static/come_on.png"></img>
						<img v-if="optionsindex>=0&&wordobj.word==item.word" class="select-icon" src="@/assets/static/great.png" ></img>
						<span class="clamp">{{optionstext[index]}}. <span>其他三个都错误</span></span>
					</div>
				</div>
				
			</div>
			<div style="width: 100%;margin-top: 28px;display: flex;justify-content: flex-end;">
				<el-button @click="$emit('cancle')">取消返回</el-button>
			</div>
		</div>
	</div>
	<!-- 拼写 end-->
</template>

<script>
	export default {
		props: {
			showtype: {
				type: Number,
				default: () => {
					return 0;
				}
			},
			type: {
				type: Number,
				default: () => {
					return 0;
				}
			},
			list: {
				type: Array,
				default: () => {
					return [];
				}
			},
		},
		watch: {
			list: {
				immediate:true,
			    handler(newVal, objVal) {
					if(newVal&&newVal.length>0){
						this.wordList = newVal
						this.initfunc()
					}
			    },
			},
		},
		data() {
			return {
				isLinstening: false, //是否正在播放音频
				wordList:[],//单词列表
				wordobj:{},//当前学习的下标
				wordindex:0,//当前学习的下标
				optionstext:['A','B','C','D'],//选项列表
				optionsList:[],//选项列表
				optionsindex:-1,//选择的下标
				optionsflag:0,//选择正确 1是正确， 2是错误
				progresstext:0,//进度
				wordoptions:{},//选项缓存
				awtitloading: false, //防止多次点击
			};
		},
		methods: {
			//初始化
			initfunc(){
				this.wordList.forEach((item, index) => {
					try{
						item["wordTpye"] =  JSON.parse(item.definition)
					}catch(e){
						item["wordTpye"] = []
					}
				});
				this.wordindex = 0
				//刷新页面单词
				this.refreshpage()
			},
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					_this.$refs.commonaudioCom.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudioCom.load()
					    _this.$refs.commonaudioCom.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//刷新页面单词
			refreshpage(){
				this.wordobj = this.wordList[this.wordindex]
				//播放
				this.playAudioCom(this.wordobj.phAmMp3)
				this.progresstext = 0
				this.optionsList = [] //选项列表
				this.awtitloading = false
				this.optionsindex = -1 //选择的下标
				this.optionsflag = 0//选择正确 1是正确， 2是错误
				//单词对应选项
				//先看看缓存有没有选项集合
				if(this.wordoptions[this.wordobj.id]){
					this.optionsList = this.wordoptions[this.wordobj.id] //选项列表
				}else{
					//单词对应选项
					this.wordSelectoptions()
				}
				//进度
				this.getjindu()
			},
			//选择题目
			async handleoption(index){
				var _this = this
				if(this.awtitloading){
					console.log("awtitloading")
					return false;
				}else{
					this.awtitloading = true
				}
				if(this.optionsindex>=0){
					this.awtitloading = false
					return false;
				}
				this.optionsindex = index
				if(this.wordobj.word==this.optionsList[index].word){
					this.optionsflag = 1
					//正确下一个
					if(this.wordindex >= this.wordList.length-1){
						//最后一道题
						this.awtitloading = false
						this.$emit("handlefinish")
						return false;
					}
				}else{
					this.optionsflag = 2
					//取值放到错误里面
				}
				var timer = setTimeout(function(){
					clearTimeout(timer)
					_this.awtitloading = false
					if(_this.optionsflag==1){
						_this.wordindex += 1
						_this.refreshpage()
					}else{
						//打乱顺序
						_this.optionsindex = -1 //选择的下标
						_this.optionsflag = 0//选择正确 1是正确， 2是错误
						_this.optionsList = _this.shuffleArray(_this.optionsList)
						//播放
						_this.playAudioCom(_this.wordobj.phAmMp3)
					}
					
				},1000)
			},
			//单词对应选项
			wordSelectoptions(){
			 	var _this = this
				var params = {
					//lessonWordId:this.wordobj.id,
					wordId:this.wordobj.wordId,
				}
				var conurl =  "app/word/userReading/wordSelect2" //"app/word/userReading/wordSelect"
				// if(this.type==3){
				// 	//传wordId
				// 	conurl = "app/word/userReading/wordSelect2"
				// }
				this.$http.post(conurl, params).then(function(res) {
					if (res.code === 200) {
						if(res.data.length>0){
							var records = _this.shuffleArray(res.data)
							//处理词义,去除来
							records.forEach((item, index) => {
								item["optionName"] = ""
								item["partName"] = ""
								try{
									var definition = JSON.parse(item.definition)
									if(definition&&definition.length>0){
										item["partName"] = definition[0].part
										item["optionName"] = definition[0].means.join('; ')
									}
								}catch(e){
									//TODO handle the exception
								}
							});
							//存到缓存，下次不用请求
							if(!_this.wordoptions[_this.wordobj.id]){
								_this.$set(_this.wordoptions, _this.wordobj.id, records);
							}
							_this.optionsList = records
						}else{
							_this.$confirm("选项为空！", '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					} else {
						_this.$confirm("选项为空！", '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//打乱顺序
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
				  // 生成[0, i]范围内的随机索引
				  const j = Math.floor(Math.random() * (i + 1));
				  // 交换array[i]和array[j]
				  [array[i], array[j]] = [array[j], array[i]];
				}
				return array;
			},
			//进度
			getjindu(){
				const num = this.wordindex + 1
				if(num){
					this.progresstext = Number(((num / this.wordList.length)  * 100).toFixed(0));
				}else{
					this.progresstext = 0
				}
			},
			// 拼接
			join(str,key){
				var reg = new RegExp((`(${key})`), "gm");
				//var replace = `<span class="sentence-wrod-style">I need some children's <span class="sentence-wrod-style-block"><div class="sentence-key-wrod-style"></div><span class="sentence-key-wrod-text">crayons</span></span>.</span>`
				var replace = '<span class="sentence-wrod-style-block"><span class="sentence-key-wrod-style"></span><span class="sentence-key-wrod-text" style="font-weight:bold;">$1</span></span>';
				return str.replace(reg, replace);
			},
			
		},
	};
</script>

<style lang="scss" scoped>
	.home_conbox {
		width: 100%;
		height: 100%;
		.progress-out {
			width: 100%;
			height: 12px;
			border-radius: 12px;
			position: relative;
			display: flex;
			align-items: center;
			background: #F0EDED;
			font-size: 14px;
			margin-bottom: 10px;
			.progress-in {
				border-radius: 12px;
				background: #51D9A5;
				height: 12px;
			}
					
			.now-num {
				height: 12px;
				position: absolute;
				top: 0;
				left: 18px;
				line-height: 12px;
				color: #5C3704;
			}
					
			.whold-num {
				height: 12px;
				position: absolute;
				top: 0;
				right: 18px;
				color: #5C3704;
				line-height: 12px;
			}
		}
	}
	.content-panel {
		width: 100%;
		min-height: 548px;
		max-height: 588px;
		overflow-y: auto;
		position: relative;
		z-index: 2;
		border-radius: 10px;
		border: 1px solid #FBC483;
		background: #FFFFFF;
		padding: 28px 18px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		.word-text {
			font-weight: bold;
			color: #222222;
			font-size: 25px;
			margin-top: 18px;
		}
		.master {
			color: #222;
			font-size: 18px;
			font-weight: bold;
			position: relative;
			display: flex;
			word-break: break-all;
			span {
				position: relative;
				z-index: 8;
			}
			
			.box-showdow {
				position: absolute;
				width: 100%;
				height: 10px;
				background: #fdf0cf;
				left: 3px;
				bottom: 6px;
				border-radius: 4px;
			}
		}
			
		.linsten-block {
			margin-top: 7px;
			display: flex;
			align-items: center;
			
			span {
				font-size: 16px;
				margin-left: 12px;
				color: #666;
			}
			
			.linsten-btn {
				border-radius: 20px;
				border: 1px solid #e0e0e0;
				background: #fff;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				height: 24px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			
				&:hover {
					transform: scale(1.05);
				}
			
				img {
					width: 16px;
					height: 16px;
				}
			}
		}
			
		.desc-item {
			display: flex;
			margin-top: 15px;
			line-height: 18px;
			&:first-child {
				font-size: 16px;
				color: #999999;
			}
			.desc2 {
				font-size: 14px;
				color: #222222;
				margin-left: 5px;
			}
		}
			
		.word-img {
			width: 263px;
			height: 136px;
			border-radius: 12px;
			margin-top: 20px;
			overflow: hidden;
		}
			
		.mid-line {
			border-bottom: dashed 1px #dbdbdb;
			width: 298px;
			margin: 20px 0;
		}
			
		.s-tip {
			width: 263px;
			font-size: 15px;
			font-weight: bold;
			color: #5c3704;
		}
			
		.select-list {
			display: flex;
			flex-direction: column;
			width: 298px;
			margin-top: 16px;
			
			.select-item {
				display: flex;
				align-items: center;
				margin-bottom: 12px;
				height: 55px;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				background: #ffffff;
				border-radius: 8px;
				padding: 0 20px;
				position: relative;
				border: solid 1px #e5e5e5;
				font-weight: bold;
				cursor: pointer;
				font-size: 15px;
				&:hover {
					border: solid 1px #aaa;
				}
			}
			.error {
				background: #f85b59 !important;
				color: #fff !important;
			}
			
			.right {
				background: #51d9a5 !important;
				color: #fff !important;
			}
			
			.select-icon {
				height: 28px;
				position: absolute;
				right: 0;
				top: -31px;
				object-fit: contain;
			}
		}
	
	}
	
</style>