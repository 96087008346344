<template>
	<div class="page game_page">
		<!-- 音频 -->
		<audio controls ref="commonaudio" class="disnone">
			<source src="" />
		</audio>
		<!-- 音频 -->
		<div class="game_bg_img">
			<img class="img100" src="../assets/game/game_box_bg.png" />
		</div>
		<!-- <div class="w1000" style="position: relative;z-index: 2;">
			<topHeader :isShowBack="false" :isShowline="false"></topHeader>
		</div> -->
		<div class="block30"></div>
		<div class="w1000">
			<div class="game_page_box" style="position: relative;z-index: 2;">
				<div class="leftbox">
					<div class="box1">
						<div class="titlename"><img class="icon" src="../assets/game/t3.png" /></div>
						<div class="conbox">
							<div class="item" :class="{'active':myPointSum.branchNum>=20}">
								<img src="../assets/game/s20.png" class="icon"></img>
								<div class="text">{{myPointSum.branchNum>=20?"已点亮":"未点亮"}}</div>
							</div>
							<div class="item" :class="{'active':myPointSum.branchNum>=50}">
								<img src="../assets/game/s50.png" class="icon"></img>
								<div class="text">{{myPointSum.branchNum>=50?"已点亮":"未点亮"}}</div>
							</div>
							<div class="item" :class="{'active':myPointSum.branchNum>=100}">
								<img src="../assets/game/s100.png" class="icon"></img>
								<div class="text">{{myPointSum.branchNum>=100?"已点亮":"未点亮"}}</div>
							</div>
							<div class="item" :class="{'active':myPointSum.signNum>=10}">
								<img src="../assets/game/q10.png" class="icon"></img>
								<div class="text">{{myPointSum.signNum>=10?"已点亮":"未点亮"}}</div>
							</div>
							<div class="item" :class="{'active':myPointSum.signNum>=30}">
								<img src="../assets/game/q30.png" class="icon"></img>
								<div class="text">{{myPointSum.signNum>=30?"已点亮":"未点亮"}}</div>
							</div>
							<div class="item" :class="{'active':myPointSum.signNum>=60}">
								<img src="../assets/game/q60.png" class="icon"></img>
								<div class="text">{{myPointSum.signNum>=60?"已点亮":"未点亮"}}</div>
							</div>
							<div class="item" :class="{'active':myPointSum.woodenTop>=10}">
								<img src="../assets/game/h10.png" class="icon"></img>
								<div class="text">{{myPointSum.woodenTop>=10?"已点亮":"未点亮"}}</div>
							</div>
							<div class="item" :class="{'active':myPointSum.woodenTop>=30}">
								<img src="../assets/game/h30.png" class="icon"></img>
								<div class="text">{{myPointSum.woodenTop>=30?"已点亮":"未点亮"}}</div>
							</div>
						</div>
						<div class="morebox" @click="handleshow('showprize')">
							<div class="text">更多</div>
							<img src="../assets/game/game_rightgo.png" class="icon"></img>
						</div>
					</div>
					<div class="box2">
						<div class="titlename"><img class="icon" src="../assets/game/t2.png" /></div>
						<div class="conbox">
							<div class="imgbg">
								<img class="img100" src="../assets/game/ditu.png" />
							</div>
							<div class="icon hlzb">
								<img class="img100" src="../assets/game/hlzb.png" />
							</div>
							<div class="icon hpcy">
								<img class="img100" src="../assets/game/hpcy.png" />
							</div>
							<div class="icon tzdh">
								<img class="img100" src="../assets/game/tzdh.png" />
							</div>
							<div class="icon wlkq">
								<img class="img100" src="../assets/game/wlkq.png" />
							</div>
						</div>
					</div>
				</div>
				<!-- 游戏页面 -->
				<div class="rightbox">
					<div class="box1" v-if="taskobj&&myPointobj">
						<div class="titlename"><img class="icon" src="../assets/game/t1.png" /></div>
						<div class="conbox_mouse" v-if="showmoveimg" @mouseout="handleTouchout" @mousemove="handleTouchMove" @mouseup="handleTouchEnd" @mousedown="handleTouchStart"></div>
						<div class="conbox">
							<img class="top-light" src="../assets/game/light_img.png" desc="光线" mode=""></img>
							<img class="top-light1" src="../assets/game/light_img1.png" desc="光线1" mode=""></img>
							<img class="top-sky" src="../assets/game/sky_bg.png" desc="背景天" mode=""></img>

							<div class="righttopimg">
								<img @click="handleshow('showrule')" class="top-rules"
									src="../assets/game/rules_btn.png" desc="规则" mode=""></img>
								<!-- <img @click="handleshow('showprize')" class="top-prize"
									src="../assets/game/prize_btn.png" desc="勋章" mode=""></img> -->
								<img @click="handleshow('showsignup')" class="top-sign-in"
									src="../assets/game/sign_in.png" desc="签到" mode=""></img>
							</div>

							<div>
								<div class="top-tree">
									<img class="num-icon" src="../assets/game/tree.png" desc="树" mode=""></img>
									<div class="num-text">
										<span>累计树枝数量</span>
										<span>{{myPointSum.branchNum}}</span>
									</div>
								</div>
								<div class="top-wall">
									<img class="num-icon" src="../assets/game/wall.png" desc="墙" mode=""></img>
									<div class="num-text">
										<span>累计水坝高度</span>
										<span>{{myPointSum.woodenTop}}</span>
									</div>
								</div>
							</div>

							<!-- ==================上面是顶部的元素==================== -->

							<!-- 河面的上边距离屏幕顶部固定是39%，中间的元素都根据这个线进行定位 -->
							<img class="mid-river-up" src="../assets/game/river_top.png" desc="河面" mode=""></img>

							<!-- ==================下面是中间的元素==================== -->
							<img class="mid-water-line1" src="../assets/game/up_water_line.png" desc="河线" mode=""></img>
							<img class="mid-water-line2" src="../assets/game/up_water_line.png" desc="河线" mode=""></img>
							<img class="mid-water-line3" src="../assets/game/up_water_line.png" desc="河线" mode=""></img>

							<img class="mid-flower" src="../assets/game/flower.png" desc="花" mode=""></img>
							<img class="mid-mountain-l" src="../assets/game/mountain_left.png" desc="左边山" mode=""></img>
							<img class="mid-mountain-r" src="../assets/game/mountain_right.png" desc="右边山"
								mode=""></img>
							<img class="mid-pubu" src="../assets/game/pubu.png" desc="瀑布" mode=""></img>
							<img class="mid-green-l" src="../assets/game/green_left.png" desc="左边远山" mode=""></img>
							<img class="mid-green-r" src="../assets/game/green_right.png" desc="右边远山" mode=""></img>
							<img class="mid-cloud-big" src="../assets/game/cloud_big.png" desc="大云" mode=""></img>
							<img class="mid-cloud-mid" src="../assets/game/cloud_mid.png" desc="左云" mode=""></img>
							<img class="mid-cloud-small" src="../assets/game/cloud_small.png" desc="小云" mode=""></img>
							<div desc="风车大">
								<img class="mid-fan-1" src="../assets/game/fan_1.png" mode=""></img>
								<img class="mid-gan-1" src="../assets/game/gan_1.png" mode=""></img>
							</div>
							<div desc="风车小">
								<img class="mid-fan-2" src="../assets/game/fan_2.png" mode=""></img>
								<img class="mid-gan-2" src="../assets/game/gan_2.png" mode=""></img>
							</div>
							<!-- ==================中间木块区域==================== -->
							<div class="mukuai_area_box" v-if="myPointobj">
								<div class="listbox">
									<div class="itemobj" v-for="(item,index) in myPointobj.woodenNum" :key="index">
										<img class="img100" src="../assets/game/brick.png" mode="aspectFill"></img>
									</div>
								</div>
							</div>
							<div class="move_area_box" id="move_area_box"></div>
							<img v-if="showmoveimg" :style="{top: imageTop + 'px', left: imageLeft + 'px'}"
								class="move_area_img" src="../assets/game/main_board_finish.png"></img>

							<!-- ==================下面是底部的元素==================== -->
							<img class="upupup" src="../assets/game/upupup.png" mode=""
								v-if="myPointobj.woodenPercent>=100"></img>
							<img v-if="myPointobj.woodenPercent==0" class="botom-main-board"
								src="../assets/game/left_boad.png" desc="主要木板未满100%" mode=""></img>
							<img v-if="myPointobj.woodenPercent>0&&myPointobj.woodenPercent<100"
								class="botom-main-board" src="../assets/game/main_board.png" desc="主要木板大于0%"
								mode=""></img>
							<img @mouseleave="mousemove" @mouseup="mouseup" @mousedown="mousedown"
								v-if="myPointobj.woodenPercent>=100" class="botom-main-board" style="z-index: 1006;"
								src="../assets/game/main_board_finish.png" desc="主要木板满100%" mode=""></img>
							<img class="botom-left-board" src="../assets/game/left_boad.png" desc="左木板" mode=""></img>
							<img class="botom-right-board" src="../assets/game/right_boad.png" desc="右木板" mode=""></img>

							<div class="progressbox" desc="进度">
								<div class="progress-bg"></div>
								<div class="progress-bg-rect"></div>
								<span class="text1">{{myPointobj.woodenPercent}}%</span>
								<span class="text2">木块拼合</span>
							</div>

							<img class="botom-water-line1" src="../assets/game/down_water_line.png" data-desc="河线"
								mode=""></img>
							<img class="botom-water-line2" src="../assets/game/down_water_line.png" data-desc="河线"
								mode=""></img>
							<img class="botom-water-line3" src="../assets/game/down_water_line.png" data-desc="河线"
								mode=""></img>
							<img class="botom-water-line4" src="../assets/game/down_water_line.png" data-desc="河线"
								mode=""></img>

							<img class="botom-river-down" src="../assets/game/river_bottom.png" data-desc="河底"
								mode=""></img>
							<img class="bottom-union" src="../assets/game/Union.png" data-desc="底下草地" mode=""></img>
							<div class="bottom-btn-group">
								<div class="image-panel" @click="handlestudy('wen')">
									<img src="../assets/game/again_btn.png" data-desc="温" mode=""></img>
								</div>
								<div class="image-panel" @click="handlestudy('ting')">
									<img src="../assets/game/linsten_btn.png" data-desc="听" mode=""></img>
								</div>
								<div class="image-panel" @click="handlestudy('pin')">
									<img src="../assets/game/spell_btn.png" data-desc="拼" mode=""></img>
								</div>
								<div class="image-panel" @click="handlestudy('xuan')">
									<img src="../assets/game/select_word_btn.png" data-desc="选" mode=""></img>
								</div>
							</div>

							<!-- 木块拼合完成 -->
							<div class="end-pop-up" v-if="myPointobj.woodenPercent>=100">
								<div class="conbox">
									<div class="text">木块拼合完成</div>
									<div class="text">长按拖拽木块</div>
									<div class="text">协助河狸筑坝</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<!-- 弹窗 -->
		<!-- 温故而知新 -->
		<div class="new-pop-up" v-if="showstudy">
			<div class="zezhao1" @click="handleclosetc()"></div>
			<div class="conbox">
				<div class="imgclose" @click="handleclosetc()">
					<img class="img100" src="../assets/game/close_game.png"></img>
				</div>
				<div class="title">{{showstudytitle}}</div>
				<div class="item" @click="handletotask(0)" :class="{'opacity7':taskobj.studyList.length<=0}">
					<span>今天新学</span>
					<span>{{taskobj.studyNum}}/{{taskobj.studyList.length}}</span>
				</div>
				<div class="item" @click="handletotask(1)" :class="{'opacity7':taskobj.reviewlist.length<=0}">
					<span>今日复习</span>
					<span>{{taskobj.fuxiNum}}/{{taskobj.reviewlist.length}}</span>
				</div>
				<div class="item" @click="handletotask(2)">
					<span>自由选词</span>
				</div>
				<div class="item" @click="handletotask(3)">
					<span>单词本</span>
				</div>
			</div>
		</div>

		<!-- 规则 -->
		<div class="yuexin-pop-up" v-if="showrule">
			<div class="zezhao1"></div>
			<div class="conbox">
				<div class="imgclose" @click="handleclosetc('showrule')">
					<img class="img100" src="../assets/game/close_game.png"></img>
				</div>
				<div class="title">游戏规则</div>
				<div class="text">1、用户可以每天进入参与游戏，温习故新、听音选义
					、单词拼写、见词名义四个任务进入选择今日新学或者今日复习完成练习内容额度获得5%木块生成加成+一个树枝。</div>
				<div class="text">2、用户完成一个任务4组练习奖励5%木块生成加成+一个树枝，完成四个任务16组练习内容额度获得5%木块生成加成+一个树枝。
				</div>
				<div class="text">3、用户每天进入游戏进行打卡获得5%木块生成加成+一个树枝。</div>
				<div class="text">
					4、勋章获取用户累计获得20树枝、50树枝、100树枝、200块树枝，勋章用户累计签到满10天、30天、60天、100天获得坚持勋章，用户累计水坝高度10、30、50、80
					获得成就勋章</div>
			</div>
		</div>
		<!-- 签到 -->
		<div class="yuexin-pop-up" v-if="showsignup">
			<div class="zezhao1" @click="handleclosetc('showsignup')"></div>
			<div class="conbox">
				<div class="imgclose" @click="handleclosetc('showsignup')">
					<img class="img100" src="../assets/game/close_game.png"></img>
				</div>
				<div class="title">每日签到</div>
				<div class="text1">很棒哦!今日又是收获的一天。</div>
				<div class="btn1 pointer" v-if="taskobj.signFlag==0" @click="getdaySign">签到</div>
				<div class="btn1 opacity5" v-if="taskobj.signFlag==1">已签到</div>
			</div>
		</div>
		<!-- 勋章 -->
		<div class="yuexin-pop-up" v-if="showprize">
			<div class="zezhao1" @click="handleclosetc('showprize')"></div>
			<div class="conbox prizecon">
				<div class="imgclose" @click="handleclosetc('showprize')">
					<img class="img100" src="../assets/game/close_game.png"></img>
				</div>
				<div class="title">我的勋章</div>
				<div class="imgbox">
					<div class="imglist">
						<div class="item" @click="handlehaibao('s',20)" :class="{'noopacity':myPointSum.branchNum<20}">
							<img class="img" src="../assets/game/s20.png"></img>
							<div class="btn1" v-if="myPointSum.branchNum>=20">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
						<div class="item" @click="handlehaibao('s',50)" :class="{'noopacity':myPointSum.branchNum<50}">
							<img class="img" src="../assets/game/s50.png"></img>
							<div class="btn1" v-if="myPointSum.branchNum>=50">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
						<div class="item" @click="handlehaibao('s',100)"
							:class="{'noopacity':myPointSum.branchNum<100}">
							<img class="img" src="../assets/game/s100.png"></img>
							<div class="btn1" v-if="myPointSum.branchNum>=100">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
						<div class="item" @click="handlehaibao('s',200)"
							:class="{'noopacity':myPointSum.branchNum<200}">
							<img class="img" src="../assets/game/s200.png"></img>
							<div class="btn1" v-if="myPointSum.branchNum>=200">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
					</div>
					<div class="imglist">
						<div class="item" @click="handlehaibao('q',10)" :class="{'noopacity':myPointSum.signNum<10}">
							<img class="img" src="../assets/game/q10.png"></img>
							<div class="btn1" v-if="myPointSum.signNum>=10">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
						<div class="item" @click="handlehaibao('q',30)" :class="{'noopacity':myPointSum.signNum<30}">
							<img class="img" src="../assets/game/q30.png"></img>
							<div class="btn1" v-if="myPointSum.signNum>=30">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
						<div class="item" @click="handlehaibao('q',60)" :class="{'noopacity':myPointSum.signNum<60}">
							<img class="img" src="../assets/game/q60.png"></img>
							<div class="btn1" v-if="myPointSum.signNum>=60">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
						<div class="item" @click="handlehaibao('q',100)" :class="{'noopacity':myPointSum.signNum<100}">
							<img class="img" src="../assets/game/q100.png"></img>
							<div class="btn1" v-if="myPointSum.signNum>=100">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
					</div>
					<div class="imglist">
						<div class="item" @click="handlehaibao('h',10)" :class="{'noopacity':myPointSum.woodenTop<10}">
							<img class="img" src="../assets/game/h10.png"></img>
							<div class="btn1" v-if="myPointSum.woodenTop>=10">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
						<div class="item" @click="handlehaibao('h',30)" :class="{'noopacity':myPointSum.woodenTop<30}">
							<img class="img" src="../assets/game/h30.png"></img>
							<div class="btn1" v-if="myPointSum.woodenTop>=30">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
						<div class="item" @click="handlehaibao('h',50)" :class="{'noopacity':myPointSum.woodenTop<50}">
							<img class="img" src="../assets/game/h50.png"></img>
							<div class="btn1" v-if="myPointSum.woodenTop>=50">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
						<div class="item" @click="handlehaibao('h',80)" :class="{'noopacity':myPointSum.woodenTop<80}">
							<img class="img" src="../assets/game/h80.png"></img>
							<div class="btn1" v-if="myPointSum.woodenTop>=80">已点亮</div>
							<div v-else class="btn2">未点亮</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 完成学习 -->
		<div class="down-pop-up" v-if="showOkTask">
			<div class="zezhao1" @click="handleclosetc('showOkTask')"></div>
			<div class="conbox">
				<div class="imgclose pointer" @click="handleclosetc('showOkTask')">
					<img class="img100" src="../assets/game/close_game.png"></img>
				</div>
				<div class="title" v-if="showsignok">完成签到</div>
				<div class="title" v-if="!showsignok">完成学习</div>
				<div class="mid-blcok">
					<img src="../assets/game/brick.png" mode=""></img>
					<span>+</span>
					<img src="../assets/game/tree.png" mode=""></img>
				</div>
				<div class="content-text">恭喜获得木块5%加成值+1树枝</div>
			</div>
		</div>
		<!-- 画板 -->
		<div class='haibao_container' v-if="savecodeImg" id="haibao_container">
			<div class="zezhao10" @click="closebsncodeimg(false)"></div>
			<div class="conbox">
				<div class="canvasbox">
					<!-- <img class="img100" src="../assets/game/haibao_bg.png" mode="widthFix"></img> -->
					<div class='daySign_middle' id='daySign_middle'>
						<canvas ref="myCanvas" width="295" height="391" canvas-id="myCanvas" disable-scroll="true" />
					</div>
				</div>
				<div class='btn1' v-if='saveImgBtnHidden'>右击图片保存</div>
			</div>
			<div class="closeicon" @click="closebsncodeimg(false)">
				<img class="img100" src="../assets/game/close_game.png"></img>
			</div>
		</div>
		<!-- 画板 -->
		<!-- 温故知新 -->
		<el-dialog :show-close="false" width="680px" :visible.sync="isWordDetail" top="0vh"
			:close-on-click-modal="false">
			<div class="progress-out">
				<div class="progress-in" :style="{width: progresstext+'%'}"></div>
				<span class="now-num">{{wordindex+1}}</span>
				<span class="whold-num">{{wordlist.length}}</span>
			</div>
			<wordCardCom class="word-card" :wordobj="wordCard"></wordCardCom>
			<div class="wordbtnbox">
				<div class="btn btn1" @click="handlelastone()">上一个</div>
				<div class="btn btn2" @click="handlenextone()">记住了</div>
			</div>
		</el-dialog>
		<!-- 选词 -->
		<el-dialog :show-close="false" width="680px" :visible.sync="isselDetail" top="0vh"
			:close-on-click-modal="false">
			<gameFree @cancle="isselDetail = false" @confirm="handlegameFree"></gameFree>
		</el-dialog>
		<!-- 单词本 -->
		<el-dialog :show-close="false" width="680px" :visible.sync="isselwordbook" top="0vh"
			:close-on-click-modal="false">
			<wordBook @cancle="isselwordbook = false" @confirm="handlewordBook"></wordBook>
		</el-dialog>
		<!-- 听 -->
		<el-dialog :show-close="false" width="680px" :visible.sync="isshowting" top="10vh"
			:close-on-click-modal="false">
			<selectCom :list="wordlist" :type="type" :showtype="1" @cancle="isshowting = false"
				@handlefinish="finishSpell"></selectCom>
		</el-dialog>
		<!-- 选 -->
		<el-dialog :show-close="false" width="680px" :visible.sync="isshowxuan" top="10vh"
			:close-on-click-modal="false">
			<selectCom :list="wordlist" :type="type" :showtype="2" @cancle="isshowxuan = false"
				@handlefinish="finishSpell"></selectCom>
		</el-dialog>
		<!-- 拼写 -->
		<el-dialog :show-close="false" width="680px" :visible.sync="isshowpin" top="3vh" :close-on-click-modal="false">
			<spellCom :list="wordlist" @handlefinish="finishSpell"></spellCom>
		</el-dialog>


		<div class="block30"></div>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import topHeader from "@/components/topHeader/topHeader.vue";
	import wordCardCom from "@/components/wordCard/wordCard.vue";
	import gameFree from "@/components/wordCard/gameFree.vue";
	import wordBook from "@/components/wordCard/wordBook.vue";
	import selectCom from "@/components/wordCard/selectCom.vue";
	import spellCom from "@/components/wordCard/spellCom.vue";
	export default {
		components: {
			topHeader,
			wordCardCom,
			gameFree,
			wordBook,
			selectCom,
			spellCom
		},
		data() {
			return {
				taskobj: null, //今日任务信息
				myPointobj: null, //用户信息
				myPointSum: {
					branchNum: 0,
					signNum: 0,
					woodenNum: 0,
					woodenTop: 0
				}, //数量
				showrule: false, //规则
				showsignup: false, //签到
				showsignok: false, //签到完成
				showprize: false, //勋章
				showmoveimg: false, //拖拽移动合成木块
				showOkTask: false, //完成一组任务
				imageTop: 0, //拖拽移动合成木块
				imageLeft: 0,
				startX: 0,
				startY: 0,
				targetArea: {
					x: 0, // 目标区域的左上角x坐标
					y: 338, // 目标区域的左上角y坐标
					width: 375, // 目标区域的宽度
					height: 158 // 目标区域的高度
				},
				showstudy: false, //温听写选
				showstudytype: "", //温听写选
				showstudytitle: "", //温听写选
				typelist: {
					'wen': {
						name: "reviewObj",
						vaule: 0,
						title: "温故知新",
					},
					'ting': {
						name: "dictationObj",
						vaule: 1,
						title: "听音见义"
					},
					'pin': {
						name: "spellObj",
						vaule: 2,
						title: "单词拼写"
					},
					'xuan': {
						name: "choiceObj",
						vaule: 3,
						title: "见词明义"
					}
				}, //选择对象
				saveImgBtnHidden: true, //画板保存按钮
				savecodeImg: false, //画板
				isWordDetail: false, //弹窗单词卡
				isselDetail: false, //弹窗自由选词
				isselwordbook: false, //弹窗单词本
				isshowting: false, //弹窗听音选词
				isshowxuan: false, //弹窗选词
				isshowpin: false, //弹窗听写
				type: 0, //类型、、0今日新学 1今日复习 2自由选词 3单词本
				steptype: "wen", //类型
				booksLesson: [], //单元
				allchecked: false, //全选按钮
				sleNum: 0, //已经选数量
				wordlist: [], //当前单词集合
				wordindex: 0, //当前学习的下标
				progresstext: 0, //进度
				wordobj: null, //当前操作单词
				wordCard: null, //当前操作单词详情
				studyingData: {
					detailwordList: [], //单词卡详情
				},
				isrenwuDone:false,//任务是否完成
				timerId:null,
				isLongPressed:false,//是否长按
			};
		},
		computed: {
			...mapState(["userInfo", "myReadingObj"]),
		},
		mounted: function() {
			//获取任务
			this.getuserGametask()
			//我的荣誉
			this.getmyPointSum()
			//数量
			this.getmyPoint()
		},
		methods: {
			//去完成任务
			handletotask(type) {
				var steptype = this.typelist[this.showstudytype].name
				this.isrenwuDone = false
				if (type == 0) {
					//学习
					if (this.taskobj.studyList.length <= 0) {
						this.$message.info("无今日学习任务")
						return false;
					}
					if (this.taskobj.todayDetail[steptype].task1Status > 0) {
						// this.$message.info("此任务已完成")
						// return false;
						this.isrenwuDone = true
					}
					this.type = type
					this.steptype = this.showstudytype
					//游戏开始
					this.startGame()
				} else if (type == 1) {
					//复习
					if (this.taskobj.reviewlist.length <= 0) {
						//this.$message.info("无复习任务")
						return false;
					}
					if (this.taskobj.todayDetail[steptype].task2Status > 0) {
						// this.$message.info("此任务已完成")
						// return false;
						this.isrenwuDone = true
					}
					this.type = type
					this.steptype = this.showstudytype
					//游戏开始
					this.startGame()
				} else if (type == 2) {
					if (this.taskobj.todayDetail[steptype].task3Status > 0) {
						// this.$message.info("此任务已完成")
						// return false;
						this.isrenwuDone = true
					}
					this.type = type
					this.steptype = this.showstudytype
					this.isselDetail = true
				} else if (type == 3) {
					if (this.taskobj.todayDetail[steptype].task4Status > 0) {
						// this.$message.info("此任务已完成")
						// return false;
						this.isrenwuDone = true
					}
					this.type = type
					this.steptype = this.showstudytype
					this.isselwordbook = true
				} else {}
			},
			//打开闻听写选弹窗
			handlestudy(type) {
				this.showstudytype = type
				var steptype = this.typelist[this.showstudytype].name
				// //完成该组任务
				// if (this.taskobj.todayDetail[steptype].allStatus == 1) {
				// 	this.handleclosetc()
				// 	this.showOkTask = true
				// 	return false;
				// }
				if (this.taskobj.todayDetail[steptype].task1Status > 0) {
					this.taskobj["studyNum"] = this.taskobj.studyList.length
				} else {
					this.taskobj["studyNum"] = 0
				}
				if (this.taskobj.todayDetail[steptype].task2Status > 0) {
					this.taskobj["fuxiNum"] = this.taskobj.reviewlist.length
				} else {
					this.taskobj["fuxiNum"] = 0
				}
				if (type == 'wen') {
					this.showstudytitle = '温故知新'
				} else if (type == 'ting') {
					this.showstudytitle = '听音见义'
				} else if (type == 'pin') {
					this.showstudytitle = '单词拼写'
				} else if (type == 'xuan') {
					this.showstudytitle = '见词明义'
				} else {}
				this.showstudy = true
				this.type = 0
				this.steptype = ""
			},
			//选单词本
			handlewordBook(list) {
				this.taskobj["wordbenList"] = list
				this.isselwordbook = false
				this.startGame()
			},
			//自由选词
			handlegameFree(list) {
				this.taskobj["freeList"] = list
				this.isselDetail = false
				this.startGame()
			},
			//游戏开始
			startGame() {
				var taskobj = this.taskobj
				this.progresstext = 0
				this.wordindex = 0
				if (this.type == 0) {
					this.wordlist = taskobj.studyList
				} else if (this.type == 1) {
					this.wordlist = taskobj.reviewlist
				} else if (this.type == 2) {
					this.wordlist = taskobj.freeList
				} else if (this.type == 3) {
					this.wordlist = taskobj.wordbenList
				} else {}
				//console.log(this.steptype)
				//判断类型
				if (this.steptype == "wen") {
					//刷新单词卡
					this.refreshcard()
				} else if (this.steptype == "ting") {
					//听音选词
					this.isshowting = true
				} else if (this.steptype == "xuan") {
					//选词见一
					this.isshowxuan = true
				} else if (this.steptype == "pin") {
					//拼写
					this.isshowpin = true
				} else {}

			},
			//完成拼写任务
			async finishSpell() {
				//请求任务完成
				this.finishTaskFuc()
			},
			//上一个
			handlelastone() {
				if (this.wordindex == 0) {
					this.$message.info("已经是第一个了")
					return false;
				}
				this.wordindex -= 1
				//刷新单词卡
				this.refreshcard()
				//进度
				this.getjindu()
			},
			//下一个
			handlenextone() {
				if (this.wordindex >= this.wordlist.length - 1) {
					//最后一个了
					//请求任务完成
					this.finishTaskFuc()
				} else {
					this.wordindex += 1
					//刷新单词卡
					this.refreshcard()
					//进度
					this.getjindu()
				}

			},
			//请求任务完成
			async finishTaskFuc() {
				var _this = this
				if (this.isrenwuDone) {
					console.log("此任务已完成")
					_this.isWordDetail = false //弹窗单词卡
					_this.isselDetail = false //弹窗自由选词
					_this.isselwordbook = false //弹窗单词本
					_this.isshowting = false //弹窗听音选词
					_this.isshowxuan = false //弹窗选词
					_this.isshowpin = false //弹窗听写
					return false;
				}
				//学习
				let params = {
					taskType1: this.typelist[this.steptype].vaule,
					taskType2: this.type,
					userUuid: this.userInfo.uuid
				}
				let res = await this.$common.commonFuc("app/word/game/userGamePoint/successTask", params)
				if (res) {
					this.isWordDetail = false
					this.$alert('此任务已经完成，去完成下一个任务吧！', '完成任务提示', {
						confirmButtonText: '确定',
						callback: action => {
							//刷新一层游戏页面任务
							//获取任务
							_this.getuserGametask()
							if (res.data.branchNum > 0) {
								//刷新全部
								//我的荣誉
								_this.getmyPointSum()
								//数量
								_this.getmyPoint()
								//完成一组任务
								_this.handleclosetc()
								_this.showOkTask = true
							} else {
								_this.isWordDetail = false //弹窗单词卡
								_this.isselDetail = false //弹窗自由选词
								_this.isselwordbook = false //弹窗单词本
								_this.isshowting = false //弹窗听音选词
								_this.isshowxuan = false //弹窗选词
								_this.isshowpin = false //弹窗听写
							}
						}
					});

				}
			},
			//刷新单词卡
			refreshcard() {
				this.isWordDetail = true
				this.wordobj = this.wordlist[this.wordindex]
				//单词卡详情
				let findword = this.studyingData.detailwordList.find(item => item.id == this.wordobj.wordId);
				if (findword) {
					this.wordCard = findword
				} else {
					//详情
					this.getdetail()
				}
				//播放
				this.playAudio(this.wordobj.phAmMp3)
			},
			//进度
			getjindu() {
				const num = this.wordindex + 1
				if (num) {
					this.progresstext = Number(((num / this.wordlist.length) * 100).toFixed(0));
				} else {
					this.progresstext = 0
				}
			},
			//查看单词是否存在，没有就加入进去
			checkwordData(obj) {
				var studyingData = this.studyingData
				let findword = studyingData.detailwordList.find(item => item.id == this.wordobj.wordId);
				if (findword) {
					return findword;
				} else {
					studyingData.detailwordList.push(obj)
					this.studyingData = studyingData
				}
			},
			//详情
			getdetail() {
				var _this = this
				var params = {
					id: this.wordobj.wordId
				}
				this.$http.post('app/word/words/detail', params).then(function(res) {
					if (res.code == 200) {
						var wordCard = res.data
						if (wordCard) {
							//查看是否存在，没有就加入进去
							_this.checkwordData(wordCard)
							_this.wordCard = wordCard
						} else {
							_this.$message.error("单词卡不存在，请返回重试")
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//播放音频
			playAudio(src) {
				var _this = this
				try {
					_this.$refs.commonaudio.src = src;
					_this.$nextTick(res => {
						_this.$refs.commonaudio.load()
						_this.$refs.commonaudio.play()
					})
				} catch (e) {
					//TODO handle the exception
				}
			},
			//打开规则，勋章，签到弹窗
			handleshow(type) {
				this.showstudy = false
				if (type == 'showrule') {
					this.showrule = true
				} else if (type == 'showsignup') {
					this.showsignup = true
				} else if (type == 'showprize') {
					this.showprize = true
				} else {}
			},
			//关闭弹窗
			handleclosetc(type) {
				this.showrule = false
				this.showsignup = false
				this.showprize = false
				this.showstudy = false
				this.showOkTask = false
				this.showsignok = false
				this.isWordDetail = false //弹窗单词卡
				this.isselDetail = false //弹窗自由选词
				this.isselwordbook = false //弹窗单词本
				this.isshowting = false //弹窗听音选词
				this.isshowxuan = false //弹窗选词
				this.isshowpin = false //弹窗听写
			},
			//获取任务
			getuserGametask() {
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid
				}
				this.$http.post('app/word/game/userGamePoint/task', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.taskobj = res.data
						if (_this.showstudy) {
							//刷新数值
							_this.handlestudy(_this.showstudytype)
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//数量
			getmyPoint() {
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid
				}
				this.$http.post('app/word/game/userGamePoint/myPoint', params).then(function(res) {
					if (res.code === 200) {
						_this.myPointobj = res.data
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//我的荣誉
			getmyPointSum() {
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid
				}
				_this.$http.post("app/word/game/userGamePoint/myPointSum", params).then(function(res) {
					if (res.code === 200) {
						_this.myPointSum = res.data
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//签到
			getdaySign() {
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid
				}
				_this.$http.post("app/word/game/userGamePoint/daySign", params).then(function(res) {
					if (res.code === 200) {
						_this.taskobj.signFlag = 1
						//完成一组任务
						_this.handleclosetc()
						_this.showsignok = true
						_this.showOkTask = true
						//数量
						_this.getmyPointSum()
						//用户积分木块
						_this.getmyPoint()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//长按
			mousedown(e){
				console.log("按住木块")
				var _this = this
				this.isLongPressed = false;
				if(this.timerId){
					clearTimeout(this.timerId);
				}
				this.timerId = setTimeout(() => {
				    _this.isLongPressed = true;
				    _this.handleLongPress(e)
				}, 1000); // 1秒钟判断为长按事件
			},
			mousemove(){
				if(!this.showmoveimg){
					console.log('移开，长按事件取消');
					if(this.timerId){
						clearTimeout(this.timerId);
					}
					this.isLongPressed = false;
				}
			},
			mouseup(){
				if(!this.showmoveimg){
					if(this.timerId){
						clearTimeout(this.timerId);
					}
					if (!this.isLongPressed) {
					    console.log('长按事件取消');
					}
				}
			},
			handleLongPress(event) {
				console.log("长按启动")
				this.showmoveimg = true
				// // 计算触摸点相对于图片中心的偏移量
				const centerX = 133 / 2;
				const centerY = 39 / 2;
				this.startX = centerX
				this.startY = centerY - 58
				// 初始化图片位置为触摸点位置
				this.imageTop = 536 - this.startY
				this.imageLeft = 203 - this.startX
			},
			//监听手指
			handleTouchStart(event) {
				console.log("Touchstart",event)
				if (this.myPointobj.woodenPercent < 100) {
					return false;
				}
				// const touch = event
				// // 计算触摸点相对于图片中心的偏移量
				// const centerX = 133 / 2;
				// const centerY = 39 / 2;
				// this.startX = centerX
				// this.startY = centerY
				// // 初始化图片位置为触摸点位置
				// this.imageTop = touch.clientY - this.startY;
				// this.imageLeft = touch.clientX - this.startX;
			},
			handleTouchMove(event) {
				//console.log("Touchmove",event)
				if (this.myPointobj.woodenPercent < 100) {
					return false;
				}
				if (!this.showmoveimg) {
					return false;
				}
				const rect = event.target.getBoundingClientRect();
				const x = event.clientX - rect.left;
				const y = event.clientY - rect.top;
				//console.log(x,y)
				this.imageTop = y - this.startY;
				this.imageLeft = x - this.startX;
			},
			handleTouchEnd(event) {
				console.log("move TouchEnd")
				const rect = event.target.getBoundingClientRect();
				const x = event.clientX - rect.left;
				const y = event.clientY - rect.top;
				console.log(x,y)
				if (this.myPointobj.woodenPercent < 100) {
					return false;
				}
				if (!this.showmoveimg) {
					return false;
				}
				this.showmoveimg = false
				const isInside = this.isInsideArea(this.imageLeft, this.imageTop);
				if (isInside) {
					console.log('图片拖拽到指定区域内');
					//合成
					this.handlecompound()
				} else {
					console.log('图片未拖拽到指定区域内');
					this.$message.info("请将木块拖拽到箭头指向区域位置")
				}
				//恢复
				this.imageTop = 0 //拖拽移动合成木块
				this.imageLeft = 0
				this.startX = 0
				this.startY = 0
			},
			//合成
			handlecompound() {
				var _this = this
				let number = Number(this.myPointobj.woodenPercent)
				var woodenNum = Math.floor(number / 100);
				if (woodenNum <= 0) {
					return false;
				}
				var params = {
					userUuid: this.userInfo.uuid,
					woodenNum: woodenNum
				}
				this.$http.post('app/word/game/userGamePoint/compound', params).then(function(res) {
					if (res.code === 200) {
						_this.$message({
							offset:155,
						    message:"合成" + woodenNum + "块成功",
						    type: 'success'
						});
						//获取任务
						_this.getuserGametask()
						//数量
						_this.getmyPointSum()
						//用户积分木块
						_this.getmyPoint()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//超出
			handleTouchout(){
				this.$message.info("请将木块拖拽到箭头指向区域位置")
				this.showmoveimg = false
				//恢复
				this.imageTop = 0 //拖拽移动合成木块
				this.imageLeft = 0
				this.startX = 0
				this.startY = 0
			},
			isInsideArea(x, y) {
				return x >= this.targetArea.x && x <= this.targetArea.x + this.targetArea.width &&
					y >= this.targetArea.y && y <= this.targetArea.y + this.targetArea.height;
			},
			//海报弹窗
			closebsncodeimg(type) {
				this.savecodeImg = type
			},
			//生成海报
			handlehaibao(type, num) {
				var _this = this
				//判断拦截
				var url = require("../assets/game/" + type + num + ".png")
				var textname = ""
				if (type == "s") {
					// if(this.myPointSum.branchNum<num){
					// 	this.$message.info("勋章未获得！")
					// 	return false;
					// }
					textname = "我已经完成" + num + "轮学习"
				} else if (type == "q") {
					if (this.myPointSum.signNum < num) {
						this.$message.info("勋章未获得！")
						return false;
					}
					textname = "我已经坚持学习" + num + "天"
				} else {
					if (this.myPointSum.woodenTop < num) {
						this.$message.info("勋章未获得！")
						return false;
					}
					textname = "我已经成功筑起水坝" + num + "米"
				}
				this.savecodeImg = true
				var timer = setTimeout(function() {
					clearTimeout(timer)
					const canvas = _this.$refs.myCanvas;
					var ctx = canvas.getContext('2d');
					// 设置背景,算出比率
					// console.log(canvas)
					var bw = 295
					var bh = 391
					//背景
					const imagebg = new Image();
					imagebg.src = require("@/assets/game/haibao_bg.png")
					imagebg.onload = () => {
						ctx.drawImage(imagebg, 0, 0, bw, bh)
						// // 勋章
						//console.log(url)
						const imageprize = new Image();
						imageprize.src = url
						imageprize.onload = () => {
							ctx.drawImage(imageprize, 87.5, 84.5, 120, 120)
						};
						//文案
						ctx.font = '14px OPPOSans';
						ctx.fillStyle = "#DA9361"
						ctx.textAlign = 'center'
						ctx.fillText(textname, 147.5, 238)
						ctx.fillText("欢迎来挑战我", 147.5, 258)
						// _this.$cache.showSuccessToast("生成分享图成功")
					};
				}, 588)
			},

		},
		
	};
</script>
<style lang="scss" scoped>
	/deep/ .el-dialog {
		background: unset !important;
	}

	/deep/ .el-dialog__header {
		display: none;
	}

	/deep/ .el-dialog__body {
		padding: 0;
		padding-top: 28px;
	}

	/deep/ .el-dialog__body .bodycon {
		padding: 30px 20px;
		height: 100%;
		background: #fbf7f5;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.word-card {
		background: #fff;
	}

	.progress-out {
		width: 100%;
		height: 12px;
		border-radius: 12px;
		position: relative;
		display: flex;
		align-items: center;
		background: #F0EDED;
		font-size: 14px;
		margin-bottom: 10px;

		.progress-in {
			border-radius: 12px;
			background: #51D9A5;
			height: 12px;
		}

		.now-num {
			height: 12px;
			position: absolute;
			top: 0;
			left: 18px;
			line-height: 12px;
			color: #5C3704;
		}

		.whold-num {
			height: 12px;
			position: absolute;
			top: 0;
			right: 18px;
			color: #5C3704;
			line-height: 12px;
		}
	}

	.wordbtnbox {
		width: 88%;
		margin: 0 auto;
		padding: 18px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.btn {
			width: 46%;
			height: 48px;
			border-radius: 6px;
			color: #fff;
			font-size: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.btn1 {
			border: 1px solid #499DFF;
			background: #67ADFF;
		}

		.btn2 {
			border: 1px solid #68CDA6;
			background: #51D9A5;
		}
	}

	.game_page {
		width: 100%;
		// background: #FFF6EB;
		position: relative;

		.game_bg_img {
			width: 100%;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;

			.img100 {
				object-fit: cover;
			}
		}

		.game_page_box {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: flex-start;
			// transform: scale(0.9);
			// transform-origin: 0 0;

			.leftbox {
				width: 486px;

				.box1 {
					width: 100%;
					padding: 24px;
					box-sizing: border-box;
					border-radius: 60px;
					background: #FFF;
					box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
					position: relative;

					.titlename {
						width: 100%;
						position: absolute;
						z-index: 2;
						left: 0;
						top: -25px;
						display: flex;
						justify-content: center;

						.icon {
							width: 159px;
							height: 65px;
						}
					}

					.conbox {
						width: 100%;
						border-radius: 40px;
						background: #FFFCF1;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						padding: 28px 0;
						position: relative;

						.item {
							width: 109px;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							border-radius: 16px;
							margin-top: 13px;
							opacity: 0.38;

							.icon {
								width: 80px;
								height: 80px;
							}

							.text {
								width: 84px;
								height: 26px;
								line-height: 26px;
								text-align: center;
								border-radius: 25px;
								color: #BC5A24;
								font-size: 12px;
								border: 1px solid #F8D948;
								background: #F8D948;
								box-shadow: 0px 4px 0px 0px #F2B541;
							}
						}

						.active {
							opacity: 1;

							.text {
								border-radius: 25px;
								border: 1px solid #FED56B;
								background: #FED56B;
								box-shadow: 0px 2px 0px 0px #F9C337;
								color: #fff;
							}
						}
					}

					.morebox {
						width: 100%;
						margin-top: 10px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						cursor: pointer;

						.text {
							color: #BC5A24;
							font-size: 18px;
							margin-right: 6px;
						}

						.icon {
							width: 32px;
							height: 32px;
						}
					}
				}

				.box2 {
					margin-top: 38px;
					width: 100%;
					padding: 24px;
					box-sizing: border-box;
					border-radius: 60px;
					background: #FFF;
					box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
					position: relative;

					.titlename {
						width: 100%;
						position: absolute;
						z-index: 2;
						left: 0;
						top: -25px;
						display: flex;
						justify-content: center;

						.icon {
							width: 159px;
							height: 65px;
						}
					}

					.conbox {
						width: 100%;
						height: 100%;
						position: relative;

						.imgbg {
							width: 100%;
							height: 446px;
						}

						.hlzb {
							width: 112px;
							height: 141px;
							position: absolute;
							z-index: 1;
							top: 37px;
							left: 222px;
						}

						.icon {
							width: 112px;
							height: 141px;
							position: absolute;
							z-index: 1;
						}

						.hpcy {
							left: 27px;
							top: 108px;
						}

						.tzdh {
							right: 27px;
							top: 198px;
						}

						.wlkq {
							left: 58px;
							top: 278px;
						}
					}
				}
			}

			.rightbox {
				width: 486px;
				margin-left: 18px;
				position: relative;
				.box1 {
					width: 100%;
					padding: 24px;
					box-sizing: border-box;
					border-radius: 60px;
					background: #FFF;
					box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
					position: relative;

					.titlename {
						width: 100%;
						position: absolute;
						z-index: 2;
						left: 0;
						top: -25px;
						display: flex;
						justify-content: center;

						.icon {
							width: 205px;
							height: 65px;
						}
					}
					.conbox_mouse{
						width: 100%;
						height: 864px;
						position: absolute;
						left: 0;
						top: 0;
						z-index:9999;
					}
					.conbox {
						width: 100%;
						height: 864px;
						position: relative;
						overflow: hidden;
						border-radius: 38px;

						// 宽高238x128px  34x16
						.mukuai_area_box {
							width: 238px;
							height: 128px;
							position: absolute;
							left: 50%;
							margin-left: -119px;
							top: calc(44vh);
							z-index: 2;
							display: flex;
							flex-direction: column;
							align-items: flex-end;

							.listbox {
								margin-top: auto;
								width: 100%;
								// height: 128px;
								display: flex;
								flex-wrap: wrap;
								justify-content: center;
								// align-items: flex-end;
								position: relative;
								transform-origin: center center;
								transform: rotate(180deg);

								.itemobj {
									width: 34px;
									height: 16px;
								}
							}
						}

						.mid-pubu {
							width: 184px;
							height: 158px;
							position: absolute;
							left: 50%;
							margin-left: -92px;
							top: calc(45vh + 2px);
							z-index: 1;
							animation: 5s pubuBoard linear infinite;
						}

						@keyframes pubuBoard {
							0% {
								top: calc(45vh + 2px);
							}

							50% {
								top: calc(45vh + 15px);
							}

							100% {
								top: calc(45vh + 2px);
							}
						}

						.move_area_box {
							width: 100%;
							height: 158px;
							position: absolute;
							left: 0;
							top: calc(41vh);
							z-index: 5;
						}

						.move_area_img {
							width: 133px;
							height: 93px;
							position: absolute;
							z-index: 8888;
							// transform: scale(0.8);
						}

						.end-pop-up {
							width: 160px;
							height: 88px;
							position: absolute;
							z-index: 1111;
							border-radius: 8px;
							border: 1px #DA9361 solid;
							top: calc(39vh - 50px);
							box-shadow: 0px 2px 8px 0px #00000066;
							left: calc(50% - 80px);
							background: #FFFFFF;
							display: flex;
							align-items: center;
							flex-direction: column;

							.conbox {
								width: 100%;
								height: 100%;
								position: relative;
								z-index: 88;
								padding: 8px;
								box-sizing: border-box;

								.imgclose {
									width: 38px;
									height: 38px;
									position: absolute;
									z-index: 2;
									right: 18px;
									top: 18px;
								}
							}

							.text {
								text-align: center;
								font-size: 16px;
								color: #DA9361;
								line-height: 23px;
								font-weight: bold;
								letter-spacing: 1px;
							}
						}

						// ============上面

						.top-light {
							position: absolute;
							width: 279px;
							height: 161px;
							top: 0;
							z-index: 2;
							left: 16px;
						}

						.top-light1 {
							position: absolute;
							top: 0;
							width: 293px;
							height: 161px;
							z-index: 2;
							left: 100px;
						}

						.top-sky {
							width: 100%;
							top: 0px;
							position: absolute;
							left: 0;
						}

						.righttopimg {
							width: 100%;
							padding-top: 38px;
						}

						.top-rules {
							position: absolute;
							width: 51px;
							height: 60px;
							z-index: 5;
							right: 20px;
							margin-top: 20px;
							cursor: pointer;
						}

						.top-sign-in {
							position: absolute;
							width: 49px;
							height: 60px;
							z-index: 5;
							right: 20px;
							margin-top: 98px;
							cursor: pointer;
						}

						.top-prize {
							position: absolute;
							width: 51px;
							height: 60px;
							z-index: 5;
							right: 20px;
							margin-top: 81px;
							cursor: pointer;
						}

						.top-wall {
							position: absolute;
							z-index: 10;
							left: 20px;
							display: flex;
							align-items: center;
							height: 24px;
							margin-top: 78px;

							img {
								position: relative;
								z-index: 1;
								width: 28px;
								height: 28px;
							}

							.num-text {
								padding-left: 20px;
								background: rgba(255, 255, 255, 0.6);
								border-radius: 0 24px 24px 0;
								min-width: 158px;
								font-size: 14px;
								height: 32px;
								position: absolute;
								left: 13px;
								display: flex;
								align-items: center;
								color: #234658;

								span:last-child {
									font-size: 16px;
									font-weight: bold;
									margin-left: 3px;
								}
							}
						}

						.top-tree {
							position: absolute;
							z-index: 10;
							left: 20px;
							margin-top: 20px;
							display: flex;
							align-items: center;

							img {
								width: 26px;
								height: 34px;
								position: relative;
								z-index: 1;
							}

							.num-text {
								padding-left: 20px;
								background: rgba(255, 255, 255, 0.6);
								border-radius: 0 24px 24px 0;
								min-width: 158px;
								font-size: 14px;
								height: 32px;
								position: absolute;
								left: 13px;
								top: 4px;
								display: flex;
								align-items: center;
								color: #234658;

								span:last-child {
									font-size: 16px;
									font-weight: bold;
									margin-left: 3px;
								}
							}
						}

						// 分界线河的样式
						.mid-river-up {
							position: absolute;
							width: 100%;
							height: 50px;
							z-index: 2;
							top: 39vh;
							left: 0;
						}



						.mid-water-line1 {
							width: 92px;
							height: 1px;
							position: absolute;
							z-index: 5;
							top: calc(39vh + 22px);
							left: 166px;
						}

						.mid-water-line2 {
							width: 69px;
							height: 1px;
							left: 54px;
							position: absolute;
							z-index: 5;
							top: calc(39vh + 15px);
						}

						.mid-water-line3 {
							width: 60px;
							height: 1px;
							left: 242px;
							position: absolute;
							z-index: 5;
							top: calc(39vh + 9px);
						}



						.mid-flower {
							position: absolute;
							width: 147px;
							height: 199px;
							z-index: 5;
							left: 0;
							top: calc(39vh - 212px);
						}


						.mid-mountain-l {
							width: 176px;
							height: 275px;
							position: absolute;
							left: -32px;
							top: calc(39vh - 42px);
							z-index: 3;
						}

						.mid-mountain-r {
							width: 162px;
							height: 245px;
							position: absolute;
							right: -25px;
							z-index: 3;
							top: calc(39vh - 18px);
						}

						.mid-green-l {
							width: 93px;
							height: 58px;
							position: absolute;
							left: 0;
							z-index: 2;
							top: calc(39vh - 55px);
						}

						.mid-green-r {
							width: 94px;
							height: 56px;
							position: absolute;
							right: 0;
							z-index: 2;
							top: calc(39vh - 54px);
						}

						@keyframes cloudAnimation {
							0% {
								opacity: 0;
								left: 220px;
							}

							50% {
								opacity: 1;
								left: 200px;
							}

							100% {
								opacity: 0;
								left: 180px;
							}
						}

						.mid-cloud-small {
							width: 150px;
							height: 30px;
							position: absolute;
							z-index: 1;
							top: 111px;
							animation: cloudAnimation linear 8s infinite;
						}

						.mid-cloud-big {
							width: 318px;
							height: 122px;
							position: absolute;
							right: 0;
							z-index: 1;
							top: calc(39vh - 120px);
						}


						.mid-cloud-mid {
							width: 146px;
							height: 45px;
							position: absolute;
							left: 0;
							z-index: 1;
							top: calc(39vh - 140px);
						}

						@keyframes fanAnimation {
							from {
								transform: rotate(0deg);
							}

							to {
								transform: rotate(360deg);
							}
						}

						.mid-fan-1 {
							width: 88px;
							height: 88px;
							position: absolute;
							left: 104px;
							z-index: 2;
							top: calc(39vh - 92px);
							animation: fanAnimation linear 7s infinite;
						}

						.mid-gan-1 {
							width: 6px;
							height: 55px;
							position: absolute;
							left: 140px;
							z-index: 1;
							top: calc(39vh - 54px);
						}

						.mid-fan-2 {
							width: 46px;
							height: 46px;
							position: absolute;
							left: 239px;
							z-index: 2;
							top: calc(39vh - 52px);
							animation: fanAnimation linear 5s infinite;
						}

						.mid-gan-2 {
							width: 4px;
							height: 32px;
							position: absolute;
							left: 260px;
							z-index: 1;
							top: calc(39vh - 31px);
						}

						// =============底下

						@keyframes mainBoard {
							0% {
								bottom: calc(30.5vh - 104px);
							}

							50% {
								bottom: calc(30.5vh - 110px);
							}

							100% {
								bottom: calc(30.5vh - 104px);
							}
						}

						.botom-main-board {
							width: 133px;
							height: 93px;
							position: absolute;
							left: calc(50% - 67px);
							z-index: 1005;
							animation: 5s mainBoard linear infinite;
						}


						@keyframes minBoard {
							0% {
								transform: translateY(2px);
							}

							50% {
								transform: translateY(0);
							}

							100% {
								transform: translateY(2px);
							}
						}

						.botom-left-board {
							width: 52px;
							height: 37px;
							position: absolute;
							left: 30px;
							z-index: 1005;
							bottom: calc(30.5vh - 110px);
							animation: 7s minBoard linear infinite;
						}

						.botom-right-board {
							width: 40px;
							height: 28px;
							position: absolute;
							right: 35px;
							z-index: 1005;
							bottom: calc(30.5vh - 75px);
							animation: 4s minBoard linear infinite;
						}

						.botom-water-line1 {
							bottom: calc(30.5vh - 18px);
							width: 99px;
							height: 2px;
							left: -9px;
							position: absolute;
							z-index: 1004;
						}

						.botom-water-line2 {
							width: 110px;
							height: 2px;
							left: 271px;
							bottom: calc(30.5vh - 5px);
							position: absolute;
							z-index: 1004;
						}

						.botom-water-line3 {
							width: 83px;
							height: 2px;
							left: 239px;
							bottom: calc(30.5vh - 93px);
							position: absolute;
							z-index: 1004;
						}

						.botom-water-line4 {
							width: 55px;
							height: 2px;
							left: 77px;
							bottom: calc(30.5vh + 12px);
							position: absolute;
							z-index: 1004;
						}

						.bottom-union {
							width: 100%;
							height: 128px;
							bottom: 0;
							position: absolute;
							left: 0;
							z-index: 1002;
						}

						.botom-river-down {
							width: 100%;
							left: 0;
							position: absolute;
							bottom: 20px;
							z-index: 1;
							height: calc(61vh - 170px);
						}

						.upupup {
							width: 20px;
							height: 57px;
							bottom: calc(30.5vh + 60px);
							left: calc(50% - 10px);
							position: absolute;
							z-index: 1008;
						}

						.progressbox {
							position: absolute;
							z-index: 1008;
							width: 98px;
							height: 65.6px;
							left: calc(50% - 48px);
							display: flex;
							flex-direction: column;
							align-items: center;
							bottom: calc(30.5vh - 10px);
							color: #FFFFFF;
							font-weight: bold;

							.progress-bg {
								background: #48727F;
								width: 100%;
								position: absolute;
								height: 58px;
								border-radius: 8px;
							}

							.progress-bg-rect {
								background: #48727F;
								width: 8px;
								height: 8px;
								transform: rotate(45deg);
								position: absolute;
								bottom: 4px;
							}

							.text1 {
								font-size: 18px;
								position: relative;
								z-index: 2;
								margin-top: 2px;
							}

							.text2 {
								font-size: 16px;
								z-index: 3;
							}
						}

						.bottom-btn-group {
							width: 100%;
							bottom: 25px;
							position: absolute;
							z-index: 1003;
							left: 0;
							display: flex;
							align-items: center;
							height: 72px;
							justify-content: center;

							.image-panel {
								position: relative;
								width: 25%;
								height: 72px;
								cursor: pointer;
							}

							img {
								width: 100%;
								height: 100%;
							}
						}

					}
				}
			}
		}
	}

	// 弹窗
	.zezhao1 {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
	}

	// 弹窗
	.new-pop-up {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 18;

		.zezhao1 {
			background: rgba(0, 0, 0, 0.58);
		}

		.conbox {
			width: 385px;
			position: relative;
			z-index: 88;
			padding: 38px;
			box-sizing: border-box;
			border-radius: 16px;
			border: 2px #DA9361 solid;
			box-shadow: 0px 4px 11.5px 0px #00000066;
			background: #FFFFFF;

			.imgclose {
				width: 28px;
				height: 28px;
				position: absolute;
				z-index: 2;
				right: 18px;
				top: 18px;
				cursor: pointer;
			}
		}

		.title {
			text-align: center;
			color: #222222;
			font-size: 22px;
			font-weight: bold;
		}

		.item {
			box-sizing: border-box;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			line-height: 36px;
			font-size: 15px;
			color: #234658;
			margin: 18px auto 0;
			font-weight: bold;
			padding: 0 28px;
			background: #FFF7EE;
			height: 58px;
			border-radius: 80px;
			box-shadow: 0px 4px 11.5px 0px #0000001F;
			cursor: pointer;
		}

	}

	.yuexin-pop-up {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 18;

		.zezhao1 {
			background: rgba(0, 0, 0, 0.58);
		}

		.conbox {
			width: 388px;
			position: relative;
			z-index: 88;
			padding: 35px 28px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			border-radius: 16px;
			border: 2px #DA9361 solid;

			.imgclose {
				width: 28px;
				height: 28px;
				position: absolute;
				z-index: 2;
				right: 18px;
				top: 18px;
			}

			.title {
				margin-top: 8px;
				text-align: center;
				color: #222222;
				font-size: 22px;
				font-weight: bold;
			}

			.text {
				margin-top: 18px;
				font-weight: 500;
				font-size: 15px;
				color: #DA9361;
			}

			.text1 {
				text-align: center;
				padding: 28px 0;
				font-weight: 500;
				font-size: 16px;
				color: #DA9361;
			}

			.btn1 {
				width: 258px;
				margin: 0 auto;
				height: 58px;
				line-height: 58px;
				border-radius: 12px;
				border: 1px solid #68CDA6;
				background: #51D9A5;
				text-align: center;
				color: #FFFFFF;
				font-size: 16px;
			}

			.imgbox {
				display: flex;
				justify-content: space-between;

				.imglist {
					width: 128px;

					.item {
						width: 100%;
						height: 128px;
						margin-top: 16px;
						border-radius: 16px;
						background: #F9F5E9;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						box-sizing: border-box;

						.img {
							width: 78px;
							height: 78px;
						}

						.btn1 {
							width: 66px;
							height: 23px;
							line-height: 23px;
							border-radius: 48px;
							border: 1px solid #FED56B;
							background: #FED56B;
							box-shadow: 0px 2px 0px 0px #F9C337;
							text-align: center;
							color: #FFFFFF;
							font-size: 14px;
						}

						.btn2 {
							width: 66px;
							height: 23px;
							line-height: 23px;
							border-radius: 48px;
							border: 1px solid #FED56B;
							background: #FED56B;
							box-shadow: 0px 2px 0px 0px #F9C337;
							text-align: center;
							color: #BC5A24;
							font-size: 14px;
						}
					}
				}
			}
		}

		.prizecon {
			width: 496px !important;
		}
	}

	.noopacity {
		opacity: 0.38;
	}

	.opacity5 {
		opacity: 0.5;
	}

	.opacity7 {
		opacity: 0.7;
	}

	.down-pop-up {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 18;

		.zezhao1 {
			background: rgba(0, 0, 0, 0.58);
		}

		.conbox {
			width: 484px;
			height: 300px;
			position: relative;
			z-index: 88;
			padding: 40px;
			box-sizing: border-box;
			border-radius: 16px;
			border: 2px #DA9361 solid;
			box-shadow: 0px 4px 11.5px 0px #00000066;
			background-color: #FFFFFF;

			.imgclose {
				width: 38px;
				height: 38px;
				position: absolute;
				z-index: 2;
				right: 18px;
				top: 18px;
			}
		}

		.title {
			text-align: center;
			color: #222222;
			font-size: 22px;
			font-weight: bold;
		}

		.mid-blcok {
			margin-top: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #DA9361;

			span {
				margin: 0 40px;
			}

			image:first-child {
				width: 80px;
				height: 40px;
			}

			image:last-child {
				width: 60px;
				height: 72px;
			}
		}

		.content-text {
			text-align: center;
			margin-top: 12px;
			font-weight: bold;
			font-size: 28px;
			color: #DA9361;
		}
	}

	.haibao_container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 28;

		.zezhao10 {
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
			background: rgba(0, 0, 0, 0.58);
		}

		.conbox {
			width: 295px;
			height: 445px;
			background-color: #FFFFFF;
			position: relative;
			z-index: 888;

			.canvasbox {
				width: 100%;
				height: 391px;

				.daySign_middle {
					width: 100%;
					height: 391px;

					.canvas {
						width: 100%;
						height: 100%;
					}
				}
			}

			.btn1 {
				width: 215px;
				height: 36px;
				line-height: 36px;
				border-radius: 38px;
				border: 1px solid #68CDA6;
				background: #51D9A5;
				color: #FFFFFF;
				text-align: center;
				font-size: 16px;
				margin: 9px auto 0;
			}
		}

		.closeicon {
			width: 25px;
			height: 25px;
			margin: 58px auto 0;
			position: relative;
			z-index: 888;
		}
	}
</style>