<template>
	<div class="selbooklist">
		<div class="titlename">单词本</div>
		<div class="book-list" v-if="booksLesson.length<=0">
			<div class="book-item" :class="{'active':curindex==index}" @click="toWordBookDetail(index)" v-for="(item, index) in dataList" :key="index">
				<img src="@/assets/static/danciben.png" alt=""/>
				<div class="book-title">{{item.title}}</div>
				<div class="book-num">共{{item.wordNum}}单词</div>
			</div>
		</div>
		<div class="bookbtn" v-if="booksLesson.length<=0"><el-button @click="$emit('cancle')">取消返回</el-button></div>
		<!-- 内容 -->
		<div class="gametask_box" v-if="booksLesson.length>0">
			<div class="home_conbox">
				<!-- 列表 -->
				<div class="word-list">
					<div class="listbox" v-for="(itemOut, listIdx) in booksLesson" :key="listIdx">
						<!-- 时间列 -->
						<div class="time-item">
							<img @click="handlechecked('lesson',listIdx,0)" v-if="!itemOut.checked" class="radio-class" src="@/assets/static/circle.png" alt="" />
							<img @click="handlechecked('lesson',listIdx,0)" v-if="itemOut.checked" class="radio-class" src="@/assets/static/check-circle-filled.png" alt="" />
							<div class="time">{{itemOut.title}}</div>
							<div class="words-num">
								共<span style="color: #f00">{{itemOut.wordList.length}}</span>词
							</div>
						</div>
						<div v-if="itemOut.isopen">
							<!--  eslint-disable  -->
							<div class="word-item" v-for="(item, index) in itemOut.wordList"
								:key="index" @click="handlechecked('word',listIdx,index)">
								<img v-if="!item.checked" class="radio-class" src="@/assets/static/circle.png" alt="" />
								<img v-if="item.checked" class="radio-class" src="@/assets/static/check-circle-filled.png" alt="" />
								<!-- 单词 -->
								<div class="word-col">
									<div>{{item.word}}</div>
									<div class="word-desc">
										<span class="desc1" v-for="(sitem, sindex) in item.definitionList"
											:key="sindex">{{ sitem.part }}
											<span class="desc2" v-for="(mitem, mindex) in sitem.means"
												:key="mindex">{{ mitem }}<span v-html="'&nbsp;'"></span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 按钮 -->
			<div class="btn-group">
				<div class="text1">已选：{{sleNum}}词</div>
				<div class="cancle-btn" @click="booksLesson = []">返回单词本</div>
				<div class="sub-btn" @click="handleopenstudy">开始学习</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import {mapState} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		data() {
			return {
				keyword:"",
				curindex:0,//当前下标
				dataList:[],//
				booksLesson:[],//外层数据
				sleNum:0,//已经选数量
			};
		},
		computed: {
			...mapState(["userInfo"]),
		},
		created() {
			//数据
			this.userCollectBooks()
		},
		methods: {
			//确定选择
			handleopenstudy(){
				var boolist = []
				this.booksLesson.forEach((item, index) => {
					item.wordList.forEach((sitem, sindex) => {
						if(sitem.checked){
							boolist.push(sitem)
						}
					});
				});
				this.$emit("confirm",boolist)
			},
			//选择勾选
			handlechecked(type,index,mindex){
				let booksLesson = this.booksLesson
				//单元选择按钮
				if(type=="lesson"){
					//单选全选
					let havelist = booksLesson[index].wordList.filter(item => !item.checked);
					if(havelist.length>0){
						booksLesson[index].checked = true
						booksLesson[index].wordList.forEach((item, index) => {
							item["checked"] = true
						});
						
					}else{
						booksLesson[index].checked = false
						booksLesson[index].wordList.forEach((item, index) => {
							item["checked"] = false
						});
					}
				}else{
					//选择单词
					booksLesson[index].wordList[mindex].checked = !booksLesson[index].wordList[mindex].checked
					//检测是不是全选完了，就把单元的变成选择
					let havelist = booksLesson[index].wordList.filter(item => !item.checked);
					if(havelist.length>0){
						//还要没选的单词
						booksLesson[index].checked = false
					}else{
						booksLesson[index].checked = true
					}
				}
				this.booksLesson = booksLesson
				//获取选词数量
				this.getSelNum()
			},
			//获取选词数量
			getSelNum(){
				var sleNum = 0
				this.booksLesson.forEach((item, index) => {
					item.wordList.forEach((sitem, sindex) => {
						if(sitem.checked){
							sleNum++
						}
					});
				});
				this.sleNum = sleNum
			},
			//详情
			userCollectBooksWord(uid){
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid,
					collectBookId:uid,
					currentPage:1,
					pageSize:8888
				}
				showLoading()
				this.$http.post('app/word/userCollectBooksWord/index', params).then(function(res) {
					hideLoading()
					if (res.code === 200) {
						var records = res.data.records
						records.forEach((item, index) => {
							item["definitionList"] = []
							item["checked"] = false
							try{
								item["definitionList"] = JSON.parse(item.definition)
							}catch(e){
								item["definitionList"] = []
								//TODO handle the exception
							}
						});
						_this.booksLesson = [
							{
								title:_this.dataList[_this.curindex].title,
								isopen:true,
								checked:false,
								wordList:records
							}
						]
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//创建
			handlecreate(type){
				var _this = this
				if(this.keyword){
					var params = {
						title: this.keyword,
						userUuid: this.userInfo.uuid
					}
					this.$http.post('app/word/userCollectBooks/save', params).then(function(res) {
						//数据处理
						if (res.code == 200) {
							_this.isShowAdd= false
							_this.keyword = ""
							if(type==1){
								_this.$message.success("创建成功！")
								//刷新
								_this.userCollectBooks()
							}else{
								_this.userCollectBooks()
							}
						} else {
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
				}else{
					this.$message.error("请输入名称")
				}
			},
			//数据
			userCollectBooks(){
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid,
					currentPage:1,
					pageSize:888
				}
				this.$http.post('app/word/userCollectBooks/index', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.dataList = res.data
						if(res.data.length<=0){
							_this.keyword = "默认单词本"
							//创建
							_this.handlecreate(2)
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//详情
			toWordBookDetail(index) {
				this.curindex = index
				this.sleNum = 0
				this.booksLesson = []
				this.userCollectBooksWord(this.dataList[this.curindex].id)
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/css/main.scss";
	.selbooklist{
		width: 100%;
		background-color: #fff;
		padding: 18px;
		box-sizing: border-box;
		border-radius: 8px;
		.titlename{
			font-size: 18px;
			font-weight: bold;
			color: #222222;
			text-align: center;
		}
	}
	.book-list {
		width: 100%;
		min-height: 538px;
		display: flex;
		flex-wrap: wrap;
		.book-item {
			width: 200px;
			height: 270px;
			border-radius: 12px;
			background: #fff;
			box-shadow: 0px 3px 12px 0px #0000001a;
			margin: 10px 10px 0 0;
			padding: 16px;
			cursor: pointer;
			&.active {
				border: #FF6C19 1px solid;
			}
			img {
				width: 100%;
				height: 187px;
				border-radius: 8px;
			}

			.book-title {
				color: #222222;
				margin-top: 5px;
				@include text-line(1);
				font-size: 16px;
				font-weight: bold;
			}

			.book-num {
				color: #666666;
				font-size: 14px;
			}

			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}
	.gametask_box{
		width: 100%;
		display: flex;
		flex-direction: column;
		// justify-content: center;
		align-items: center;
		position: relative;
		z-index: 8;
		background-color: #fff;
		border-radius: 8px;
		.home_conbox {
			width: 100%;
			overflow-y: auto;
			position: relative;
			z-index: 2;
			.titlename{
				font-size: 18px;
				font-weight: bold;
				color: #222222;
				text-align: center;
			}
			.word-list {
				min-height: 538px;
				max-height: 588px;
				flex: 1;
				overflow-y: auto;
				padding: 20px 0;
				margin-right: 10px;
				border-radius: 8px;
				box-shadow: 0px 3px 10px 0px #f8f8f8;
				flex-shrink: 0;
				height: 100%;
				.listbox{
					padding-bottom: 10px;
				}
				.time-item {
					height: 50px;
					border-bottom: solid 1px #e5e5e5;
					display: flex;
					align-items: center;
					cursor: pointer;
					transition: 0.3s all;
					color: #222;
					font-weight: bold;
					font-size: 16px;
					&:hover {
						border-bottom: solid 1px #67adff;
					}
					.time{
						flex: 1;
					}
					.words-num {
						margin-left: auto;
						margin-right: 2px;
						color: #666666;
						font-size: 14px;
						font-weight: normal;
					}
				}
			
				.word-checked {
					box-shadow: 0px 3px 15.5px 0px #0000001a;
					background: #fff !important;
				}
			
				.word-item {
					width: 100%;
					display: flex;
					align-items: center;
					height: 74px;
					margin: 8px 10px 0px 0px;
					background: #f7f7f7;
					border-radius: 8px;
					cursor: pointer;
					transition: all 0.3s;
					border: #f7f7f7 1px solid;
					padding: 0 12px;
			
					.word-col {
						flex: 1;
						div {
							@include text-line(1);
							font-size: 16px;
							color: #222222;
							font-weight: bold;
							&:last-child {
								margin-top: 4px;
								font-size: 12px;
								color: #999999;
								font-weight: normal;
							}
						}
						.word-desc{
							width: 100%;
						}
					}
			
					&:hover {
						border: #e2e2e2 1px solid;
					}
				}
				.radio-class {
					width: 28px;
					height: 28px;
					transition: all 0.3s;
					margin-right: 12px;
					&:hover {
						transform: scale(1.05);
					}
				}
			
				&::-webkit-scrollbar {
					width: 6px;
				}
			
				&:hover {
					&::-webkit-scrollbar-thumb {
						background: #cfcfcf; // 滑块颜色
					}
				}
			
				&::-webkit-scrollbar-thumb {
					background: #efefef; // 滑块颜色
					border-radius: 5px; // 滑块圆角
				}
			
				&::-webkit-scrollbar-thumb:hover {
					background: #c2c2c2; // 鼠标移入滑块变红
				}
			
				&::-webkit-scrollbar-track {
					border-radius: 6px; // 轨道圆角
					background-color: #f7f7f7; // 轨道颜色
				}
			}
		}
	}
	.btn-group {
		flex-shrink: 0;
		width: 100%;
		background: #fff;
		border-radius: 12px;
		display: flex;
		align-items: center;
		padding-left: 18px;
		box-sizing: border-box;
		border-radius: 8px;
		background: #e0e0e0;
		.radio-class {
			width: 40px;
			height: 40px;
		}
	
		span {
			font-size: 16px;
			margin-left: 16px;
		}
		
		.text1{
			font-size: 16px;
		}
		.cancle-btn{
			margin-left: auto;
			width: 158px;
			height: 58px;
			background: #999;
			color: #fff;
			font-weight: bold;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		.sub-btn {
			width: 158px;
			height: 58px;
			background: #51D9A5;
			color: #fff;
			font-weight: bold;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}
	.bookbtn{
		width: 100%;
		background: #fff;
		margin-top: 18px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	
</style>