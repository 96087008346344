<template>
	<!-- 内容 -->
	<div class="gametask_box">
		<div class="home_conbox">
			<div class="titlename">自由选词</div>
			<!-- 列表 -->
			<div class="word-list">
				<div class="listbox" v-for="(itemOut, listIdx) in booksLesson" :key="listIdx">
					<!-- 时间列 -->
					<div class="time-item">
						<img @click="handlechecked('lesson',listIdx,0)" v-if="!itemOut.checked" class="radio-class" src="@/assets/static/circle.png" alt="" />
						<img @click="handlechecked('lesson',listIdx,0)" v-if="itemOut.checked" class="radio-class" src="@/assets/static/check-circle-filled.png" alt="" />
						<div class="time" @click="handleopenword(listIdx)">{{itemOut.title}}</div>
						<div class="words-num" @click="handleopenword(listIdx)">
							共<span style="color: #f00">{{itemOut.wordList.length}}</span>词
						</div>
						<i class="el-icon-arrow-down" @click="handleopenword(listIdx)" v-if="itemOut.isopen"></i>
						<i class="el-icon-arrow-right" @click="handleopenword(listIdx)" v-else></i>
					</div>
					<div v-if="itemOut.isopen">
						<!--  eslint-disable  -->
						<div class="word-item" v-for="(item, index) in itemOut.wordList"
							:key="index" @click="handlechecked('word',listIdx,index)">
							<img v-if="!item.checked" class="radio-class" src="@/assets/static/circle.png" alt="" />
							<img v-if="item.checked" class="radio-class" src="@/assets/static/check-circle-filled.png" alt="" />
							<!-- 单词 -->
							<div class="word-col">
								<div>{{item.word}}</div>
								<div class="word-desc">
									<span class="desc1" v-for="(sitem, sindex) in item.definitionList"
										:key="sindex">{{ sitem.part }}
										<span class="desc2" v-for="(mitem, mindex) in sitem.means"
											:key="mindex">{{ mitem }}<span v-html="'&nbsp;'"></span>
										</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 按钮 -->
		<div class="btn-group">
			<div class="text1">已选：{{sleNum}}词</div>
			<div class="cancle-btn" @click="$emit('cancle')">取消</div>
			<div class="sub-btn" @click="handleopenstudy">开始学习</div>
		</div>
		
	</div>
</template>

<script>
	//引入状态管理
	import {mapState} from 'vuex'
	export default {
		data() {
			return {
				bgnum: 0, //顶部渐变
				booksLesson:[],//单元
				allchecked:false,//全选按钮
				sleNum:0,//已经选数量
			}
		},
		mounted() {
			//数据
			this.getbooksLesson()
		},
		//状态值
		computed: {
			...mapState(['userInfo', 'myReadingObj']),
		},
		methods: {
			//开始学习
			handleopenstudy(){
				const loading = this.$loading({
				    lock: true,
				    text: '加载中....',
				    spinner: 'el-icon-loading',
				    background: 'rgba(0, 0, 0, 0.7)'
				});
				setTimeout(() => {
				  loading.close();
				}, 1000);
				var freeList = []
				this.booksLesson.forEach((item, index) => {
					item.checked = false
					item.wordList.forEach((sitem, sindex) => {
						if(sitem.checked){
							sitem.checked = false
							freeList.push(sitem)
						}
					});
				});
				this.sleNum = 0
				this.$emit("confirm",freeList)
			},
			//选择打开详情
			handleopenword(index){
				let booksLesson = this.booksLesson
				booksLesson[index].isopen = !booksLesson[index].isopen
				this.booksLesson = booksLesson
			},
			//选择勾选
			handlechecked(type,index,mindex){
				let booksLesson = this.booksLesson
				//单元选择按钮
				if(type=="lesson"){
					//单选全选
					let havelist = booksLesson[index].wordList.filter(item => !item.checked);
					if(havelist.length>0){
						booksLesson[index].checked = true
						booksLesson[index].wordList.forEach((item, index) => {
							item["checked"] = true
						});
						
					}else{
						booksLesson[index].checked = false
						booksLesson[index].wordList.forEach((item, index) => {
							item["checked"] = false
						});
					}
				}else{
					//选择单词
					booksLesson[index].wordList[mindex].checked = !booksLesson[index].wordList[mindex].checked
					//检测是不是全选完了，就把单元的变成选择
					let havelist = booksLesson[index].wordList.filter(item => !item.checked);
					if(havelist.length>0){
						//还要没选的单词
						booksLesson[index].checked = false
					}else{
						booksLesson[index].checked = true
					}
				}
				this.booksLesson = booksLesson
				//获取选词数量
				this.getSelNum()
			},
			//获取选词数量
			getSelNum(){
				var sleNum = 0
				this.booksLesson.forEach((item, index) => {
					item.wordList.forEach((sitem, sindex) => {
						if(sitem.checked){
							sleNum++
						}
					});
				});
				this.sleNum = sleNum
			},
			//数据
			getbooksLesson() {
				var _this = this
				var params = {
					currentPage: 1,
					pageSize: 8888,
					bookId:this.myReadingObj.bookId
				}
				this.$http.post('app/word/booksLesson/indexWord', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data
						records.forEach((witem, windex) => {
							witem["isopen"] = false
							witem["checked"] = false
							witem.wordList.forEach((item, index) => {
								item["definitionList"] = []
								item["checked"] = false
								try{
									item["definitionList"] = JSON.parse(item.definition)
								}catch(e){
									item["definitionList"] = []
									//TODO handle the exception
								}
							});
						});
						_this.booksLesson = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/css/main.scss";
	.gametask_box{
		width: 100%;
		display: flex;
		flex-direction: column;
		// justify-content: center;
		align-items: center;
		position: relative;
		z-index: 8;
		background-color: #fff;
		border-radius: 8px;
		.home_conbox {
			width: 100%;
			flex: 1;
			overflow-y: auto;
			position: relative;
			z-index: 2;
			padding: 18px 0;
			box-sizing: border-box;
			.titlename{
				font-size: 18px;
				font-weight: bold;
				color: #222222;
				text-align: center;
			}
			.word-list {
				min-height: 558px;
				max-height: 588px;
				flex: 1;
				overflow-y: auto;
				padding: 20px;
				margin-right: 10px;
				border-radius: 8px;
				box-shadow: 0px 3px 10px 0px #f8f8f8;
				flex-shrink: 0;
				height: 100%;
				.listbox{
					padding-bottom: 10px;
				}
				.time-item {
					height: 50px;
					border-bottom: solid 1px #e5e5e5;
					display: flex;
					align-items: center;
					cursor: pointer;
					transition: 0.3s all;
					color: #222;
					font-weight: bold;
					font-size: 16px;
					&:hover {
						border-bottom: solid 1px #67adff;
					}
					.time{
						flex: 1;
					}
					.words-num {
						margin-left: auto;
						margin-right: 2px;
						color: #666666;
						font-size: 14px;
						font-weight: normal;
					}
				}
			
				.word-checked {
					box-shadow: 0px 3px 15.5px 0px #0000001a;
					background: #fff !important;
				}
			
				.word-item {
					width: 100%;
					display: flex;
					align-items: center;
					height: 74px;
					margin: 8px 10px 0px 0px;
					background: #f7f7f7;
					border-radius: 8px;
					cursor: pointer;
					transition: all 0.3s;
					border: #f7f7f7 1px solid;
					padding: 0 12px;
			
					.word-col {
						flex: 1;
						div {
							@include text-line(1);
							font-size: 16px;
							color: #222222;
							font-weight: bold;
							&:last-child {
								margin-top: 4px;
								font-size: 12px;
								color: #999999;
								font-weight: normal;
							}
						}
						.word-desc{
							width: 100%;
						}
					}
			
					&:hover {
						border: #e2e2e2 1px solid;
					}
				}
				.radio-class {
					width: 28px;
					height: 28px;
					transition: all 0.3s;
					margin-right: 12px;
					&:hover {
						transform: scale(1.05);
					}
				}
			
				&::-webkit-scrollbar {
					width: 6px;
				}
			
				&:hover {
					&::-webkit-scrollbar-thumb {
						background: #cfcfcf; // 滑块颜色
					}
				}
			
				&::-webkit-scrollbar-thumb {
					background: #efefef; // 滑块颜色
					border-radius: 5px; // 滑块圆角
				}
			
				&::-webkit-scrollbar-thumb:hover {
					background: #c2c2c2; // 鼠标移入滑块变红
				}
			
				&::-webkit-scrollbar-track {
					border-radius: 6px; // 轨道圆角
					background-color: #f7f7f7; // 轨道颜色
				}
			}
		}
	}
	.btn-group {
		flex-shrink: 0;
		width: 100%;
		background: #fff;
		border-radius: 12px;
		display: flex;
		align-items: center;
		padding-left: 18px;
		box-sizing: border-box;
		border-radius: 8px;
		background: #e0e0e0;
		.radio-class {
			width: 40px;
			height: 40px;
		}
	
		span {
			font-size: 16px;
			margin-left: 16px;
		}
		
		.text1{
			font-size: 16px;
		}
		.cancle-btn{
			margin-left: auto;
			width: 158px;
			height: 58px;
			background: #999;
			color: #fff;
			font-weight: bold;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		.sub-btn {
			width: 158px;
			height: 58px;
			background: #51D9A5;
			color: #fff;
			font-weight: bold;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}
	
</style>